<template>
  <div class="table hidden-scrollbar">
    <div class="tab-row first-line sticky">
      <div class="tab-col tab-col-c tab-col-w-35">区域名称</div>
      <div class="tab-col tab-col-c tab-col-w-65">分公司名称</div>
    </div>
    <div class="tab-row" v-for="(org, index) in orgList" :key="index" @click="goto(org)">
      <div class="tab-col tab-col-c tab-col-w-35">
        {{ org.supname }}
      </div>
      <div class="tab-col tab-col-c tab-col-w-65">{{ org.name }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { apiBiPeUserOrg } from "@/api/modules/app";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store: Store = useStore();

    const dataMap = reactive({
      isRegion: 0,
      orgList: [] as any,
      isLoading: true,
    });
    const getReportPersonOrgList = () => {
      apiBiPeUserOrg()
        .then((res: any) => {
          if (res.status == 200) {
            dataMap.orgList = res.data_list;
            dataMap.isLoading = false;
          } else {
            Toast(res.msg);
          }
        })
        .catch((err) => {});
    };
    getReportPersonOrgList();

    const goto = (org: any) => {
      router.push({
        name: "personDataList",
        query: {
          depart_bazaar: org.depart_bazaar,
          depart_design: org.depart_design,
          depart_project: org.depart_project,
          fnumber: org.fnumber,
          isrespposition: org.isrespposition,
          name: org.name,
          sup_fnumber: org.sup_fnumber,
        },
      });
    };
    return {
      ...toRefs(dataMap),
      goto,
    };
  },
});
</script>

<style lang="less" scoped>
.tab-row:nth-of-type(2n) {
  background-color: #f2f2f2;
}
</style>
